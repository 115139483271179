import React from "react";
import Seo from "../../components/seo";
import Layout from "../../Layout";
import Breadcrumbs from "../../components/Breadcrumbs";
import Image1 from "./images/image7.webp";
import Image2 from "./images/images2.jpg";
import Image3 from "./images/images3.jpg";
import Image4 from "./images/images4.jpg";
import Image5 from "./images/images5.jpg";
import Image6 from "./images/images6.jpg";
import FarmImage1 from "./images/farm1.jpg";
import FarmImage2 from "./images/farm2.jpg";

const images = [Image1, Image2, Image3, Image4, Image5, Image6];

const beetrootBenefits = [
  {
    text: "Boosts Heart Health",
    details: "Helps lower blood pressure and improve circulation due to its high nitrate content.",
  },
  {
    text: "Rich in Antioxidants",
    details: "Packed with betalains, which help fight inflammation and oxidative stress.",
  },
  {
    text: "Improves Exercise Performance",
    details: "Enhances endurance and oxygen delivery to muscles, improving athletic performance.",
  },
  {
    text: "Supports Liver Detoxification",
    details: "Aids in detoxifying the liver and promoting overall liver function.",
  },
  {
    text: "Promotes Digestion",
    details: "High in fiber, which supports healthy digestion and regular bowel movements.",
  },
];

const BeetrootPage = () => (
  <Layout>
    <Seo title="Beetroot" />
    <Breadcrumbs title="Beetroot" />
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <style>
            {`
              .fixed-size { width: 120px; height: 120px; object-fit: cover; margin: 3px; }
              .narrow-paragraph { max-width: 700px; margin: 0 auto; }
              .custom-table { width: 100%; margin-top: 20px; border-collapse: collapse; }
              .custom-table th, .custom-table td { border: 1px solid #ddd; padding: 8px; }
              .custom-table th { background-color: #f2f2f2; text-align: left; }
              .custom-table .green-header { background-color: #d4edda; }
              .margin-bottom { margin-bottom: 50px; }
              .farm-description { display: flex; align-items: center; margin-bottom: 15px; }
              .farm-description img { margin-right: 15px; width: 120px; height: 120px; object-fit: cover; }
              @media (max-width: 1200px) {
                .container { padding: 15px; }
                .fixed-size { width: 110px; height: 110px; }
                .custom-table { font-size: 14px; }
              }
              @media (max-width: 992px) {
                .fixed-size { width: 100px; height: 100px; }
                .custom-table { font-size: 12px; }
                .farm-description { flex-direction: column; align-items: flex-start; }
                .farm-description img { margin-bottom: 10px; width: 100px; height: 100px; }
              }
              @media (max-width: 768px) {
                .fixed-size { width: 90px; height: 90px; }
                .custom-table { font-size: 10px; }
                .farm-description { flex-direction: column; align-items: flex-start; }
                .farm-description img { margin-bottom: 10px; width: 90px; height: 90px; }
                .narrow-paragraph { padding: 0 15px; }
              }
              @media (max-width: 576px) {
                .fixed-size { width: 80px; height: 80px; }
                .custom-table { font-size: 8px; }
                .farm-description { flex-direction: column; align-items: flex-start; }
                .farm-description img { margin-bottom: 10px; width: 80px; height: 80px; }
              }
            `}
          </style>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row">
                {images.slice(0, 3).map((image, index) => (
                  <div className="col-4" key={index}>
                    <img src={image} alt={`Beetroot field ${index + 1}`} className="img-thumbnail fixed-size" />
                  </div>
                ))}
              </div>
              <div className="row mt-2">
                {images.slice(3).map((image, index) => (
                  <div className="col-4" key={index + 3}>
                    <img src={image} alt={`Beetroot field ${index + 4}`} className="img-thumbnail fixed-size" />
                  </div>
                ))}
              </div>
              <div className="row mt-3 narrow-paragraph">
                <div className="col-12">
                  <p>
                    Beetroot, also known as beet, is a vibrant root vegetable
                    with a deep red or purple hue. It's a member of the
                    Chenopodiaceae family, which also includes chard and
                    spinach. Beetroots are not only known for their striking
                    color but also for their impressive nutritional profile.
                    Available in various forms—fresh, cooked, pickled, or
                    juiced—beetroot is a versatile and delicious addition to any
                    diet.
                  </p>
                  <p>
                    When grown organically, beetroots are free from synthetic
                    pesticides and chemicals, ensuring that you’re consuming the
                    healthiest version of this nutritious vegetable. Organic
                    beets are cultivated with natural fertilizers, promoting
                    healthier soil and supporting sustainable farming practices.
                    Choosing organic beetroot means opting for a vegetable that
                    is grown without synthetic pesticides, herbicides, and
                    fertilizers. Organic farming methods prioritize soil health
                    and biodiversity, ensuring a more environmentally friendly
                    and nutrient-dense food source.
                  </p>
                </div>
              </div>
              <h3><strong>Nutrition Table</strong></h3>
              <table className="custom-table margin-bottom">
                <thead>
                  <tr>
                    <th className="green-header">Nutrient</th>
                    <th className="green-header">Beetroot, raw</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td>Calories</td><td>43 kcal</td></tr>
                  <tr><td>Protein</td><td>1.6 g</td></tr>
                  <tr><td>Fat</td><td>0.2 g</td></tr>
                  <tr><td>Carbohydrates</td><td>9.6 g</td></tr>
                  <tr><td>Fiber</td><td>2.8 g</td></tr>
                  <tr><td>Sugars</td><td>6.8 g</td></tr>
                  <tr><td>Vitamin C</td><td>4% of DV</td></tr>
                  <tr><td>Vitamin A</td><td>0% of DV</td></tr>
                  <tr><td>Folate</td><td>20% of DV</td></tr>
                  <tr><td>Potassium</td><td>305 mg</td></tr>
                  <tr><td>Calcium</td><td>16 mg</td></tr>
                  <tr><td>Iron</td><td>0.8 mg</td></tr>
                  <tr><td>Magnesium</td><td>23 mg</td></tr>
                </tbody>
              </table>
              <h3><strong>Our Organic Farms</strong></h3>
              <div className="farm-description">
                <img src={FarmImage1} alt="Organic Farm 1" className="img-thumbnail" />
                <p>Farm at Nuwara Eliya Control Union Certified</p>
              </div>
              <div className="farm-description">
                <img src={FarmImage2} alt="Organic Farm 2" className="img-thumbnail" />
                <p>Farm at Chilaw PGS Certified</p>
              </div>
            </div>
            <div className="col-12 col-md-4 ml-auto">
              <h2>Health Benefits of Beetroots</h2>
              <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                {beetrootBenefits.map((benefit, index) => (
                  <li key={index}>
                    <span style={{ color: "green" }}>✅</span>
                    <span style={{ color: "black" }}>
                      <strong>{benefit.text}:</strong> {benefit.details}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default BeetrootPage;
